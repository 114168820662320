import React, { FC } from 'react'
import './flatView.css';

const OfferView: React.FC<{ code:any , title:any, description:any, close: any }> = ({ code,title ,description, close }) => {

    const handleClose = () => {
        close();
    }
    return (
        
            <div className='offer-modal-body'>                
                <h3>{title}</h3>
                <button type="button" className="close-btn" data-bs-dismiss="modal" onClick={handleClose}><i className="fas fa-times-circle"></i></button>
                <div className='modal-body'>
                    <h4>{description}</h4>
                    <h4 className="promo">Use Promo Code</h4>
                    <div className="offercode"><span>{code}</span></div>
                    <p>Apply this code on your checkout to avail the offer!</p>
                </div>
            </div>
    )
}

export default OfferView

/*
<div>
<div className="modal-dialog modal-dialog-centered" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">

  
   <div className="vertical-alignment-helper">
       <div className="modal-dialog vertical-align-center">
           <div className="modal-content offermodal-body">
               <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose}></button>
               <h3>{title}</h3>
               <h4>{description}</h4>
               <h4 className="promo">Use Promo Code</h4>
               <div className="offercode"><span>{code}</span></div>
               <p>Apply this code on your checkout to avail the offer!</p>
           </div>
       </div>
   </div>

</div>
</div>

*/