import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  clearUserProductCategories,
  fetchProductAllCategories,
} from "../../redux/Actions/productCategoriesAction";
// import ProductContent from './productContent';
import { Link, useNavigate } from "react-router-dom";
import { setUserProductCategories } from "./../../redux/Actions/productCategoriesAction";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import { fetchPickupDelivery } from "../../redux/Actions/checkoutPageActions";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import { fetchGetShopByPinCode } from "./../../redux/Actions/checkoutPageActions/index";
import {
  clearCart,
  clearCartCount,
  clearSplitPrice,
  deleteCartCount,
  setCartCount,
  setOrderType,
} from "../../redux/Actions/cartCountAction";
import { setValue } from "../../utility";
import { shirticon } from "../../assets/img";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { toast, ToastContainer } from "react-toastify";
const ProductLists = () => {
  const [productCategories, setProductCategories] = useState([]);
  const dispatch = useDispatch<any>();
  const state: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );

  const { cartCount }: any = useSelector<any>((state) => state);

  const settings: any = useSelector<any>((state) => state.settings);

  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [addToCarts, setAddToCarts] = useState([]);
  const [userName, setUserName] = useState<any>("");
  const [cartInformation, setCartInformation] = useState([]);
  const userData: any = useSelector<any>((state) => state);
  const navigate = useNavigate();

  const minimum_order_amount: any = useSelector<any>(
    (state) => state.getShopByPinCode?.data?.minimum_order_amount
  );

  useEffect(() => {
    if (state) {
      setAddToCarts(state.addToCarts);
    }
  }, [state]);

  useEffect(() => {
    if (selectedCategories.length) {
      const cartInformationData = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });

      if (cartInformationData.length) {
        setValue("cartInformationData", cartInformationData);
      }
      setCartInformation(cartInformationData);

      const selectedMainCategory = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });

      let finalTotalPrice = 0;
      let itemsCount = 0;

      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });
      dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
    }
  }, [selectedCategories]);

  const handleContinue = (e: any) => {
    e.preventDefault();
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    if (selectedMainCategory.length == 0) {
      toast.warning("Please select atlease one product to continue");
      return false;
    }

    const postalCode = JSON.parse(localStorage.getItem("postalCode")!);
    const shopId = localStorage.getItem("shop_id");
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: shopId,
      postal_code: postalCode,
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)

        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) *
            parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
        };
      });
    });

    payload = { ...payload, total_price: finalTotalPrice.toString() };
    localStorage.setItem("order_type", payload.order_type);
    localStorage.setItem("shop_id", payload.shop_id);
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    // if (userinfo) {
    //   const { data = { data: {} } } = userinfo;

    //   if (data) {
    //     const { user_info = {} } = data;
    //     const { Name = "" } = user_info;
    //
    if (userinfo) {
      navigate("/checkout");
    } else {
      localStorage.setItem("isCheckout", "1");
      navigate("/guestLogin");
    }
    // } else {

    //   navigate("/guestLogin");

    // }
  };
  const handleQuickOrder = () => {
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    if (userinfo) {
      dispatch(setOrderType(1));
      localStorage.removeItem("cartInformationData");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      // dispatch(clearSplitPrice());
      navigate("/checkout");
    } else {
      localStorage.removeItem("cartInformationData");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      // dispatch(clearSplitPrice());
      navigate("/guestLogin");
    }
  };

  // useEffect(()=>{
  //     dispatch(fetchAddToCart(1,"GU111BH",20,3.33,10.00,16.67,1,0,2,1,48,2,10.00,"Silk Shirt",2,1,168,0,0.00,"Cotton Shirt",0,1,48,0,0.00,"Silk Shirt",0,1,48,0,0.00,"Cotton Shirt",0,1,48,0,0.00,"Silk Shirt",0,1,48,0,0.00,"Cotton Shirt",0));
  // },[])

  useEffect(() => {
    if (state) {
      const { category = [] } = state;

      setProductCategories(category);
      if (category?.length > 0) {
        dispatch(setUserProductCategories(category));
      }
    }
  }, [state]);
  useEffect(() => {
    toast.dismiss();
    const postalCode = localStorage.getItem("postalCode");

    if (postalCode == "undefined" || postalCode == null || !postalCode) {
      toast.warning("Enter postal code in Book Now Section");
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
    dispatch(fetchProductAllCategories());
    dispatch(fetchGetShopByPinCode(postalCode));
  }, []);

  // useEffect(()=>{
  //    dispatch(setCartCount(selectedCategories))
  // },[selectedCategories]);

  // useEffect(()=>{
  //     const cartInformationData = selectedCategories?.filter((item: any) => {
  //       return item?.sub_categories?.some(
  //         (subItem: any) => parseInt(subItem?.quantity) > 0
  //       );
  //     });
  //     setCartCount(cartInformationData);
  //   },[selectedCategories])

  const onCategoryClickHandler = (selectedCategoryId: any) => {
    //

    setSelectedCategoryIndex(
      selectedCategories.findIndex(
        (obj: any) => obj.main_category_id === selectedCategoryId
      )
    );

    // setSelectedCategory(productCategories?.find(
    //   (item: any) =>
    //     item.main_category_id === (selectedCategoryId).toString()
    // ));

    /* const selectedCategoryItem: any = productCategories?.find((item: any) => item.main_category_id === selectedCategoryId);
        if (selectedCategoryItem) {
            
            dispatch(setUserProductCategories(selectedCategoryItem));
        } */
  };

  const onAddToCartItemHandler = (
    selectedSubCategoryId: any,
    selectedMainCategoryId: any,
    type: any
  ) => {
    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    if (type === "minus") {
      updatedQuantity = updatedQuantity - 1;
      dispatch(deleteCartCount(1));
    } else {
      updatedQuantity = updatedQuantity + 1;
      dispatch(setCartCount(cartCount + 1));
    }

    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  };

  function handleOptionChange(
    e: any,
    selectedMainCategoryId: any,
    selectedSubCategoryId: any
  ) {
    let selectedOption = e.target.value;
    let selectedOptionPrice = "0";

    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const getOption = getSubCategory?.options?.map((option: any) => {
      if (option.option_id == selectedOption) {
        selectedOptionPrice = option.option_price;
        return { ...option, is_default: 1 };
      } else {
        return { ...option, is_default: 0 };
      }
    });

    //

    let option = getOption;
    // let updatedQuantity = parseInt(quantity);

    const updatedSubCategory = {
      ...getSubCategory,
      options: option,
      total_price: selectedOptionPrice,
    };

    //

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  }

  // const cartInformation = selectedCategories?.filter((item: any) => {
  //   return item?.sub_categories?.some(
  //     (subItem: any) => parseInt(subItem?.quantity) > 0
  //   );
  // });

  //Construct the api request based on cartInformation

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>PRODUCT LIST</h2>
                <ul>
                  <li key={1}>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li key={2}>
                    <span> Product List</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-orderlist first-background">
        <div className="">
          <div className="container">
            <div className="selection-page-wrapper">
              <div className="list-breadcums">
                <div className="row">
                  <div className="col-md-6 col-sm-4 d-none d-md-block">
                    <ul>
                      <li key={3}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                          }}
                        >
                          Home
                        </a>
                      </li>
                      /<li key={4}>Product List</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="quick-order-btn mb-15 mobile-order-btn">
                                <a className="btn btn-lg" href="#" >Skip to <strong>Quick Order</strong> <i className="fa fa-info-circle fa-1" title="we'll collect your bag and bill you after"></i></a>
                            </div> */}

              <div className="row">
                <div className="col-sm-12 col-lg-8">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {productCategories &&
                      productCategories.map((item: any) => {
                        return (
                          <SwiperSlide>
                            <div
                              onClick={() =>
                                onCategoryClickHandler(item.main_category_id)
                              }
                              className={
                                selectedCategories[selectedCategoryIndex]
                                  .main_category_id == item.main_category_id
                                  ? "category-container active-swiper"
                                  : "category-container"
                              }
                            >
                              <div role="tab" id="heading-1">
                                <a
                                  role="button"
                                  className=""
                                  data-bs-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapse-1"
                                  aria-expanded="true"
                                  aria-controls="collapse-1"
                                >
                                  <div className="order_list orderlist-first">
                                    <div className="user-img">
                                      <img
                                        src={item.web_banner_img}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="list-explore">
                                      <p>{item.main_category_name}</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                  <div className="row mt-4">
                    <div className="col-md-12 mb-5">
                      <div className="product-list">
                      {selectedCategories?.length > 0 &&
                        selectedCategories[selectedCategoryIndex]?.sub_categories.map((item: any) => {
                          return (                     
                            <div className="product">
                              <div className="pro-det">
                                  <h5
                                    className="subcategory-name"
                                    data-sub="1"
                                  >
                                    {item.sub_category_name}
                                  </h5>
                                  <img
                                    src={item.web_sub_category_img}
                                    title={item.sub_category_name}
                                    className="d-none"
                                  />
                              </div>
                              <div className="btn-group w-100 select-btn d-none">
                                <select className="catselect" onChange={(e)=>{handleOptionChange(e, selectedCategories[selectedCategoryIndex].main_category_id, item.sub_category_id)}}>
                                  {item.options.map(
                                    (subItem: any) => {
                                      return (
                                        <option value={subItem.option_id} selected={subItem.is_default == '1' ? true : false}>
                                          {
                                            subItem?.option_description
                                          }
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                              <div className="amount">
                                <span>{settings?.WebmasterSettings?.currency}</span>
                                {parseInt(item?.quantity) === 0
                                  ? parseFloat(item?.total_price)
                                  : parseFloat(
                                    item?.total_price
                                  )}
                              </div>
                              <div className="listcloth-rht">
                                  <span>
                                    <button
                                      type="button"
                                      className="quantity-left-minus btn btn-number"
                                      data-type="minus"
                                      data-field=""
                                      disabled={
                                        item.quantity === "0"
                                      }
                                      onClick={() =>
                                        onAddToCartItemHandler(
                                          item.sub_category_id,
                                          selectedCategories[
                                            selectedCategoryIndex
                                          ].main_category_id,
                                          "minus"
                                        )
                                      }
                                    >
                                      <span className="glyphicon glyphicon-minus"></span>
                                    </button>
                                  </span>
                                  <span>
                                    <input
                                      placeholder=""
                                      className="form-control input-number inp-item-quantity quantity"
                                      id="quantity_1_1_1"
                                      name="quantity_1_1_1"
                                      type="text"
                                      value={item?.quantity}
                                    />
                                  </span>
                                  <span>
                                    <button
                                      type="button"
                                      className="quantity-right-plus btn btn-number"
                                      data-type="plus"
                                      data-field=""
                                      onClick={() =>
                                        onAddToCartItemHandler(
                                          item.sub_category_id,
                                          selectedCategories[
                                            selectedCategoryIndex
                                          ].main_category_id,
                                          "add"
                                        )
                                      }
                                    >
                                      <span className="glyphicon glyphicon-plus"></span>
                                    </button>
                                  </span>
                                </div>
                            </div>
                          );
                        })}
                      </div>
                      </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4">
                  <div className="theiaStickySidebar">
                    <div className="pl-style">
                      {settings.quick_order_mode == 1 &&
                        <div className="skiptoquickorder text-center">
                          <div className="quick-order-btn mb-15">
                            <button
                              className="btn btn-lg"
                              onClick={handleQuickOrder}
                            >
                              Skip to <strong>Quick Order</strong>
                            </button>
                          </div>
                        </div>
                      }
                      <div className="price-details Ord-summary cart-summary">
                        <div className="price-header text-center">
                          <h2>Cart summary</h2>
                        </div>
                        <div className="price-body">
                          {cartInformation?.map((item: any) => {
                            return (
                              <>
                                <div className="cs-option-price">
                                  <span className="cs-summary-title">
                                    <h4>{item?.main_category_name}</h4>
                                  </span>
                                  {item?.sub_categories
                                    ?.filter(
                                      (subItem: any) =>
                                        parseInt(subItem.quantity) > 0
                                    )
                                    ?.map((subCategory: any) => {
                                      return (
                                        <div className="cs-option-body">
                                          <div className="cs-option-left">
                                            <span className="theme-clr-primory">
                                              {subCategory?.sub_category_name}{" "}
                                            </span>
                                            <span className="cs-option-pqty">
                                              ({subCategory?.quantity})
                                            </span>
                                          </div>
                                          <div className="cs-option-right">
                                            {
                                              settings?.WebmasterSettings
                                                ?.currency
                                            }
                                            &nbsp;
                                            {(
                                              parseFloat(
                                                subCategory?.quantity
                                              ) *
                                              parseFloat(
                                                subCategory?.total_price
                                              )
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            );
                          })}
                          <div className="cs-option-amt">
                            <div className="cs-option-amt-list">
                              <div className="cs-option-amt-left">
                                Price ({selectedcategoryPriceList?.itemsCount}{" "}
                                items)
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {selectedcategoryPriceList?.total_price_amount}
                              </div>
                            </div>

                            {selectedcategoryPriceList?.vat_percentage > 0 ? (
                              <>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    Amount without VAT
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}
                                    {(
                                      parseFloat(
                                        selectedcategoryPriceList?.total_price_amount
                                      ) -
                                      parseFloat(
                                        selectedcategoryPriceList?.vat_amount
                                      )
                                    ).toFixed(2)}
                                  </div>
                                </div>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    VAT (
                                    {selectedcategoryPriceList?.vat_percentage}
                                    %)
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}{" "}
                                    {selectedcategoryPriceList?.vat_amount}
                                  </div>
                                </div>
                              </>
                            ) : null}

                            <div className="cs-option-amt-list cs-option-amt-listbg">
                              <div className="cs-option-amt-left">
                                Amount Payable
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {
                                  selectedcategoryPriceList?.final_payable_amount
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="price-footer">
                          <button
                            type="submit"
                            id="submit-btn"
                            className="continue-btn hover-btn center-block"
                            data-quantity="2"
                            data-price="70.00"
                            // disabled = {selectedcategoryPriceList?.itemsCount === 0}
                            onClick={handleContinue}
                          >
                            Continue{" "}
                            <span className="option-total total-amt">
                              {settings?.WebmasterSettings?.currency}{" "}
                              {selectedcategoryPriceList?.final_payable_amount}
                            </span>
                          </button>
                        </div>
                        <div className="text-center price-footer-notes">
                          <small>
                            <b>Note:</b> Minimum order amount is{" "}
                            {settings?.WebmasterSettings?.currency}
                            {minimum_order_amount || ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="resize-sensor">
                      <div className="resize-sensor-expand">
                        <div></div>
                      </div>
                      <div className="resize-sensor-shrink">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductLists;
