import React from 'react'
import {aboutimg,abouticon1,abouticon2,abouticon3,abouticon4} from '../../assets/img'

function AboutBestAtLaundry() {
  
  return (
    <div>
      <section className="aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img src={aboutimg} className="img-fluid aos-init" alt="" data-aos="zoom-in-up" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content-right aos-init" data-aos="zoom-in-up">
                <div className="section-title-a">
                  <h2 className="text-start">Pixie Fresh Laundry</h2>
                  <h1>Professional Dry Cleaning & Laundry Services in London.</h1>
                  <div className="section-line">
                    <span className='first-line'></span>
                  </div>
                  <div className="section-line small-line">
                    <span className='second-line'></span>
                  </div>
                </div>

                <p>When we first opened our laundry, we wanted to be able to offer the best service in London and Wigmore. To be the best, we had to buy the best. So we went ahead and invested in the finest garment cleaning machinery available. We have our own in-house jacket machine, trouser machine, shirt machine and ironing table. We don't subcontract anything. Your garments come from you, to us, and back to you.</p>
                <div className="about-icon">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon1} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Cost Effective</h5>
                          <p>Pixie Fresh Laundry provides laundry services at an affordable cost to all when compared to the market.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon2} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Ease to Use</h5>
                          <p>Our services are designed for effortless use and navigation.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon3} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Fast Delivery</h5>
                          <p>Our team will swiftly pick up and transport your laundry to you.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon4} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Quality of Service</h5>
                          <p>Our team ensures careful washing, precise dry cleaning, expert ironing, attentive folding, thorough packing, and timely return to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
    </div>
  )
}

export default AboutBestAtLaundry