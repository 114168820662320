import axios from "axios";
import React, { useEffect, useState } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { useSelector } from "react-redux";

function GoogleReviews() {
  const [widget, setWidget] = useState<any>();
  const [widgetId, setWidgetId] = useState("");
  const base_url = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios({
      method: "get",
      url: `${base_url}/api/reviews`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
      },
    })
      .then((response) => {
        if (response.status !== 401) {
          setWidgetId(response.data.data.elfsight_widget_id);
        }
      })
      .catch(function (error) {});
      setWidget(document.getElementById("eapps-google-reviews-" + widgetId))
    setTimeout(() => {
      let review = document.getElementById("eapps-google-reviews-" + widgetId);
      review?.firstChild?.lastChild?.remove();
      // console.log("ttttt",review?.firstChild)
      // if(review?.firstChild === undefined){
      //   document.getElementById("noreview")?.remove()
      // }
    }, 5000);
  }, []);
  
  return widgetId !== "" ? (
    
    <section className="greview-section" id="noreview">
        <div className="text-center aos-init" data-aos="zoom-in-up">
          <h2>Our Reviews</h2>
        </div>
        <ElfsightWidget widgetID={widgetId} />
        <div className="aos-init" data-aos="zoom-in-up">
          <div className="text-center">
            <a href="#" className="btn howitbtn hover-btn">
              Book Now
            </a>
          </div>
        </div>
    </section>

  ) : (
    <div className="text-center mt-5"></div>
  );
}

export default GoogleReviews;
